import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN_translation from './en/translation.json'
import BM_translation from './bm/translation.json'
import CN_translation from './cn/translation.json'

const resources = {
  en: {translation: EN_translation},
  bm: {translation: BM_translation},
  cn: {translation: CN_translation}
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('bereach-admin-language')  || "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;