const { REACT_APP_ENV_TYPE } = process.env;

const APP_VERSION = "2.3.32";

const admin = {
  /* Local */
  // GRAPHQL_HTTP: "http://localhost:8081/graphql",
  // GRAPHQL_WS: "ws://localhost:8081/wsql",

  /* Staging */
  GRAPHQL_HTTP: "https://api.bereach.co/staging/admin/v1/graphql",
  GRAPHQL_WS: "wss://api.bereach.co/staging/admin/v1/wsql",

  /* Production */
  // GRAPHQL_HTTP: "https://api.bereach.co/admin/v1/graphql",
  // GRAPHQL_WS: "wss://api.bereach.co/admin/v1/wsql",
}

// SUPER ADMIN
const superadmin = {
  /* Local */
  // GRAPHQL_HTTP: "http://localhost:5005/graphql",
  // GRAPHQL_WS: "ws://localhost:5005/wsql",

  /* Production */
  GRAPHQL_HTTP: "https://superadmin-server.bereach.co/graphql",
  GRAPHQL_WS: "wss://superadmin-server.bereach.co/wsql",
}

export const paymentPercent = {
  sarawakPay: {
    dine_in: 0.5,
    self_pickup: 0.5,
    delivery: 0.5,
  },
  boost: {
    dine_in: 1,
    self_pickup: 1,
    delivery: 1,
  }
}

export const product_img_limit = 5;

export default () => {
  if (REACT_APP_ENV_TYPE === "superadmin") {
    return { APP_VERSION, ...superadmin }
  }

  return { APP_VERSION, ...admin };
}
