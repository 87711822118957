export const logo = ['555 130', `
  <title>BeReach admin page</title>
  <g>
	<g>
		<path fill="#414042" d="M133.03,100.68V26.46h24.3c5.89,0,10.35,0.71,13.64,2.16c3.36,1.48,6.04,3.8,7.96,6.88
			c1.91,3.06,2.89,6.47,2.89,10.15c0,3.48-0.87,6.69-2.58,9.55c-1.07,1.78-2.45,3.36-4.14,4.72c2.21,0.99,4.01,2.09,5.45,3.31
			c2.17,1.84,3.89,4.1,5.11,6.72c1.22,2.62,1.84,5.51,1.84,8.56c0,6.2-2.3,11.5-6.83,15.78c-4.5,4.24-10.54,6.39-17.94,6.39H133.03z
			 M159.04,89.82c4.82,0,8.34-0.46,10.46-1.36c2.04-0.87,3.64-2.17,4.89-3.98c1.23-1.79,1.83-3.7,1.83-5.83
			c0-2.62-0.84-4.85-2.56-6.83c-1.75-2-4.22-3.4-7.36-4.15c-2.23-0.53-6.27-0.8-11.99-0.8H143.9v22.94H159.04z M154.35,55.82
			c4.58,0,7.94-0.41,9.98-1.23c1.93-0.77,3.42-1.94,4.54-3.57c1.12-1.61,1.66-3.37,1.66-5.37c0-2.61-0.86-4.58-2.63-6.03
			c-1.86-1.52-5.04-2.3-9.44-2.3H143.9v18.49H154.35z"/>
		<path fill="#414042" d="M219.6,101.01c-9.03,0-16.21-3.03-21.35-9.01c-5.07-5.89-7.63-12.64-7.63-20.08
			c0-7.03,2.18-13.37,6.49-18.85c5.48-7.04,12.94-10.61,22.17-10.61c9.41,0,17.06,3.65,22.73,10.85c4.02,5.07,6.08,11.44,6.11,18.94
			l0.01,2.01h-46.53c0.45,4.64,2.13,8.42,5.1,11.5c3.36,3.49,7.4,5.19,12.34,5.19c2.4,0,4.76-0.43,7.02-1.27
			c2.26-0.84,4.2-1.96,5.76-3.31c1.58-1.37,3.37-3.69,5.3-6.91l0.98-1.63l8.97,4.79l-0.86,1.74c-1.9,3.84-4.19,6.98-6.79,9.35
			c-2.61,2.37-5.58,4.2-8.83,5.43C227.34,100.39,223.65,101.01,219.6,101.01z M236.15,64.53c-0.74-2.14-1.67-3.91-2.77-5.3
			c-1.5-1.88-3.54-3.42-6.05-4.6c-2.53-1.18-5.23-1.78-8.01-1.78c-4.61,0-8.47,1.44-11.78,4.41c-1.87,1.69-3.41,4.13-4.59,7.26
			H236.15z"/>
		<path fill="#414042" d="M289.32,100.87L269.2,68.2h-6.02v32.67h-11.01V26.65h15.97c8.02,0,13.35,0.33,16.3,1
			c4.58,1.04,8.38,3.33,11.27,6.8c2.92,3.5,4.39,7.82,4.39,12.86c0,4.21-1.01,7.97-3,11.15c-2.01,3.2-4.91,5.67-8.62,7.32
			c-2.22,0.99-4.29,1.68-6.19,2.06l20.34,33.03H289.32z M273.27,57.48c4.38,0,7.64-0.4,9.65-1.19c1.87-0.73,3.28-1.87,4.3-3.47
			c1.05-1.64,1.56-3.45,1.56-5.51c0-1.95-0.52-3.68-1.58-5.29c-1.06-1.6-2.4-2.71-4.08-3.4c-1.24-0.5-3.91-1.11-9.57-1.11h-10.36
			V57.4L273.27,57.48z"/>
		<path fill="#414042" d="M326.77,101.01c-9.03,0-16.21-3.03-21.35-9.01c-5.07-5.89-7.63-12.64-7.63-20.08
			c0-7.03,2.18-13.37,6.49-18.85c5.48-7.04,12.94-10.61,22.17-10.61c9.41,0,17.06,3.65,22.73,10.85c4.02,5.07,6.08,11.44,6.11,18.94
			l0.01,2.01h-46.53c0.45,4.64,2.13,8.42,5.1,11.5c3.36,3.49,7.4,5.19,12.34,5.19c2.4,0,4.76-0.43,7.02-1.27
			c2.26-0.84,4.2-1.96,5.76-3.31c1.58-1.37,3.37-3.69,5.3-6.91l0.98-1.63l8.97,4.79l-0.86,1.74c-1.9,3.84-4.19,6.98-6.79,9.35
			c-2.61,2.37-5.58,4.2-8.83,5.43C334.51,100.39,330.81,101.01,326.77,101.01z M343.32,64.53c-0.74-2.14-1.67-3.91-2.77-5.3
			c-1.5-1.88-3.54-3.42-6.05-4.6c-2.53-1.18-5.23-1.78-8.01-1.78c-4.61,0-8.47,1.44-11.78,4.41c-1.87,1.69-3.41,4.13-4.59,7.26
			H343.32z"/>
		<path fill="#414042" d="M406.44,101.25v-7.7c-4.23,4.31-10.24,6.81-17.96,7.46l-0.08,0.01h-0.08c-8.5,0-15.54-2.95-20.95-8.76
			c-5.37-5.77-8.09-12.61-8.09-20.32c0-7.67,2.58-14.43,7.66-20.09c5.65-6.22,12.84-9.38,21.37-9.38c8.53,0,15.72,3.13,21.36,9.32
			c5.18,5.62,7.81,12.4,7.81,20.15c0.1,27.11,0.03,27.39-0.05,27.75l-0.35,1.57H406.44z M382.17,89.77
			c1.77,0.38,3.47,0.57,5.06,0.57c8.29,0,14.28-5.14,18.29-15.71c0.23-4-0.22-7.88-1.31-11.54c-0.79-1.59-1.87-3.13-3.23-4.57
			c-3.52-3.72-7.66-5.53-12.65-5.53c-5.01,0-9.2,1.81-12.82,5.55c-3.63,3.75-5.4,8.16-5.4,13.48c0,3.38,0.94,6.69,2.78,9.83
			C374.68,84.92,377.8,87.58,382.17,89.77z"/>
		<path fill="#414042" d="M452.57,101.01c-8.67,0-16-2.82-21.77-8.37c-5.79-5.57-8.73-12.45-8.73-20.43
			c0-5.39,1.38-10.45,4.09-15.05c2.71-4.6,6.47-8.24,11.18-10.82c4.68-2.57,10-3.87,15.81-3.87c3.6,0,7.15,0.56,10.54,1.66
			c3.4,1.1,6.34,2.59,8.75,4.4c2.44,1.84,4.53,4.21,6.2,7.04l0.99,1.68l-8.52,5.32l-1.1-1.47c-4.22-5.64-9.83-8.38-17.15-8.38
			c-5.86,0-10.57,1.81-14.4,5.54c-3.81,3.71-5.67,8.11-5.67,13.43c0,3.42,0.88,6.69,2.62,9.73c1.73,3.02,4.13,5.39,7.13,7.03
			c3.03,1.66,6.5,2.5,10.31,2.5c7.09,0,12.72-2.73,17.19-8.34l1.13-1.42l8.52,5.55l-1.13,1.68c-2.68,4.01-6.32,7.14-10.8,9.33
			C463.33,99.92,458.22,101.01,452.57,101.01z"/>
		<path fill="#414042" d="M522.41,101.06V74.3c0-7.23-0.38-10.27-0.7-11.54c-0.72-3-1.99-5.23-3.8-6.66
			c-1.81-1.43-4.27-2.15-7.33-2.15c-3.59,0-6.73,1.16-9.59,3.54c-2.89,2.41-4.75,5.31-5.66,8.86c-0.56,2.37-0.84,6.97-0.84,13.68
			v21.03h-10.72V25.04h10.72v26.08c1.86-1.82,3.84-3.3,5.92-4.44c3.52-1.92,7.39-2.9,11.49-2.9c4.29,0,8.15,1.11,11.47,3.29
			c3.32,2.18,5.8,5.15,7.37,8.81c1.54,3.6,2.29,8.98,2.29,16.46v28.7H522.41z"/>
	</g>
</g>
<g>
	<path fill="#414042" d="M94.67,115.48h-56.1c-12.12,0-21.95-9.83-21.95-21.95v-56.1c0-12.12,9.83-21.95,21.95-21.95h56.1
		c12.12,0,21.95,9.83,21.95,21.95v56.1C116.61,105.66,106.79,115.48,94.67,115.48z"/>
	<g>
		<g>
			<g>
				<g>
					<path fill="#4977BC" d="M75.19,78.02h13.12c0.67,0,1.32,0.27,1.8,0.74l10.06,10.06c0.48,0.48,0.74,1.12,0.74,1.8v6.98
						c0,3.37-1.95,4.18-4.34,1.8L75.19,78.02z"/>
				</g>
				<g>
					<path fill="#4977BC" d="M44.14,74.56V64.55c0,0,0-0.01,0.01-0.01h35.47c6.25,0,11.38-5.09,11.27-11.34
						c-0.11-6.05-5.06-10.94-11.14-10.94H38.68c-1.4,0-2.54-1.14-2.54-2.54v-4.94c0-1.4,1.14-2.54,2.54-2.54h40.9
						c11.85,0,21.54,9.69,21.33,21.54c-0.2,11.5-9.62,20.78-21.16,20.78L44.14,74.56C44.14,74.56,44.14,74.56,44.14,74.56z"/>
				</g>
				<g>
					<path fill="#F3F3F1" d="M36.13,100.27V50.51c0-1.4,1.14-2.54,2.54-2.54h18.86c5.09,0,9.46,2.92,11.41,7.63
						c0.76,1.83,1.07,3.72,0.94,5.63l-0.06,0.93H59.48l0.32-1.25c0.17-0.68-0.05-1.3-0.12-1.48c-0.18-0.43-0.75-1.44-2.16-1.44
						h-8.84c-1.4,0-2.54,1.14-2.54,2.54v29.72c0,1.4,1.14,2.54,2.54,2.54h16.38c1.26,0,1.77-0.9,1.93-1.29
						c0.16-0.38,0.43-1.39-0.45-2.27l-0.02-0.02l-9.54-12.73h12.98l3.67,3.67c3.53,3.53,4.54,10.58,2.63,15.19
						c-1.91,4.61-6.2,7.47-11.19,7.47h-26.4C37.27,102.81,36.13,101.67,36.13,100.27z"/>
				</g>
			</g>
		</g>
	</g>
</g>
`]
