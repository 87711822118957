import { t } from 'i18next'

const LoadingScreen = () => {
  return (
    <div className="text-center">
      <div className="spinner-border" style={{ width: "3rem", height: "3rem", marginTop: "25%" }} role="status">
        <span className="sr-only">{t("Loading...")}</span>
      </div>
    </div>
  )
}

export default LoadingScreen